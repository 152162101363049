var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-form',{attrs:{"form":_setup.form,"label-col":_setup.labelCol,"wrapper-col":_setup.wrapperCol}},[_c('a-form-item',{attrs:{"required":"","label":"活动名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'active_name',
          {
            initialValue: _setup.props.edit.name,
            rules: [
              {
                required: true,
                message: '活动名称不能为空',
              },
            ],
          },
        ]),expression:"[\n          'active_name',\n          {\n            initialValue: props.edit.name,\n            rules: [\n              {\n                required: true,\n                message: '活动名称不能为空',\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"300px","margin-right":"30px"},attrs:{"maxLength":15,"placeholder":"请输入"},on:{"change":_setup.inputChange}}),_c('span',{staticStyle:{"color":"rgb(177, 177, 177)"}},[_vm._v("还可输入"+_vm._s(_setup.inputLength ? 15 - _setup.inputLength : '15')+"个字")])],1),_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"required":"","label":"活动日期"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '200px' })},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'start_time',
            {
              initialValue: _setup.isEdit ? _setup.moment(_setup.props.edit.start_time * 1000) : '',
              rules: [
                {
                  required: true,
                  message: '活动开始时间不能为空',
                },
              ],
            },
          ]),expression:"[\n            'start_time',\n            {\n              initialValue: isEdit ? moment(props.edit.start_time * 1000) : '',\n              rules: [\n                {\n                  required: true,\n                  message: '活动开始时间不能为空',\n                },\n              ],\n            },\n          ]"}],attrs:{"disabled":_setup.isEdit,"disabledDate":_setup.disabledStartDate,"show-time":{
            hideDisabledOptions: true,
            defaultValue: _setup.moment('00:00:00', 'HH:mm:ss'),
          },"format":"YYYY-MM-DD HH:mm:ss","placeholder":"开始时间"},on:{"change":_setup.getStartTime}})],1),_c('span',{style:({ display: 'inline-block', width: '24px', textAlign: 'center' })},[_vm._v(" 至 ")]),_c('a-form-item',{style:({ display: 'inline-block', width: '200px' })},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'end_time',
            {
              initialValue: _setup.isEdit ? _setup.moment(_setup.props.edit.end_time * 1000) : '',
              rules: [
                {
                  required: true,
                  message: '活动结束时间不能为空',
                },
              ],
            },
          ]),expression:"[\n            'end_time',\n            {\n              initialValue: isEdit ? moment(props.edit.end_time * 1000) : '',\n              rules: [\n                {\n                  required: true,\n                  message: '活动结束时间不能为空',\n                },\n              ],\n            },\n          ]"}],attrs:{"disabledDate":_setup.disabledEndDate,"show-time":{
            hideDisabledOptions: true,
            defaultValue: _setup.moment('23:59:59', 'HH:mm:ss'),
          },"format":"YYYY-MM-DD HH:mm:ss","placeholder":"结束时间"},on:{"change":_setup.getEndTime}})],1)],1),_c('a-form-item',{attrs:{"required":"","label":"满减满折"}},[_c('a-radio-group',{on:{"change":_setup.auto},model:{value:(_setup.discount),callback:function ($$v) {_setup.discount=$$v},expression:"discount"}},[_c('a-radio',{staticClass:"radio",attrs:{"value":1}},[_c('span',[_vm._v("满减")]),_c('div',{staticClass:"full"},[_c('a-form-item',{staticStyle:{"display":"inline-block","margin-right":"20px"}},[_vm._v("消费满"),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'dis_money_1',
                  {
                    initialValue: _setup.props.edit.type == 1 ? _setup.props.edit.threshold_price / 100 : '',
                    rules: [
                      {
                        required: _setup.discount == 1 ? true : false,
                        message: '消费金额不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'dis_money_1',\n                  {\n                    initialValue: props.edit.type == 1 ? props.edit.threshold_price / 100 : '',\n                    rules: [\n                      {\n                        required: discount == 1 ? true : false,\n                        message: '消费金额不能为空',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"num",attrs:{"min":0,"precision":0,"disabled":_setup.discount !== 1}}),_vm._v("元 ")],1),_c('a-form-item',{staticStyle:{"display":"inline-block"}},[_vm._v("立减"),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'dis_reduce_1',
                  {
                    initialValue: _setup.props.edit.type == 1 ? _setup.props.edit.discounts_minus / 100 : '',
                    rules: [
                      {
                        required: _setup.discount == 1 ? true : false,
                        message: '优惠金额不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'dis_reduce_1',\n                  {\n                    initialValue: props.edit.type == 1 ? props.edit.discounts_minus / 100 : '',\n                    rules: [\n                      {\n                        required: discount == 1 ? true : false,\n                        message: '优惠金额不能为空',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"num",attrs:{"min":0,"precision":0,"disabled":_setup.discount !== 1}}),_vm._v("元 ")],1)],1)]),_c('a-radio',{staticClass:"radio",attrs:{"value":2}},[_c('span',[_vm._v("满折")]),_c('div',{staticClass:"full"},[_c('a-form-item',{staticStyle:{"display":"inline-block","margin-right":"20px"}},[_vm._v("消费满"),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'dis_money_2',
                  {
                    initialValue: _setup.props.edit.type == 2 ? _setup.props.edit.threshold_price / 100 : '',
                    rules: [
                      {
                        required: _setup.discount == 2 ? true : false,
                        message: '消费金额不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'dis_money_2',\n                  {\n                    initialValue: props.edit.type == 2 ? props.edit.threshold_price / 100 : '',\n                    rules: [\n                      {\n                        required: discount == 2 ? true : false,\n                        message: '消费金额不能为空',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"num",attrs:{"min":0,"precision":0,"disabled":_setup.discount !== 2}}),_vm._v("元 ")],1),_c('a-form-item',{staticStyle:{"display":"inline-block"}},[_vm._v("立打"),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'dis_reduce_2',
                  {
                    initialValue: _setup.props.edit.type == 2 ? _setup.props.edit.discounts_ratio / 10 : '',
                    rules: [
                      {
                        required: _setup.discount == 2 ? true : false,
                        message: '优惠折扣不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'dis_reduce_2',\n                  {\n                    initialValue: props.edit.type == 2 ? props.edit.discounts_ratio / 10 : '',\n                    rules: [\n                      {\n                        required: discount == 2 ? true : false,\n                        message: '优惠折扣不能为空',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"num",attrs:{"disabled":_setup.discount !== 2,"precision":1,"min":0,"max":10}}),_vm._v("折 ")],1)],1)])],1)],1),_c('a-form-item',{staticStyle:{"position":"relative","top":"-50px"},attrs:{"required":"","label":"优惠叠加"}},[_c('a-radio-group',{model:{value:(_setup.overlay),callback:function ($$v) {_setup.overlay=$$v},expression:"overlay"}},[_c('a-radio',{staticClass:"radio",attrs:{"value":2}},[_vm._v("不可叠加")]),_c('a-radio',{staticClass:"radio",attrs:{"value":1,"disabled":_setup.discount == 2}},[_vm._v("可叠加")])],1),_c('p',{staticStyle:{"width":"570px"}},[_vm._v(" 说明：满折活动优惠不可叠加，满减活动优惠可叠加，例如自动：满100-50，满200-100； ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }